import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-icon-flat.svg';

export const NoMatch = ({ hideLink = false }) => {
  return (
    <div>
      <div className='text-center'>
        <a href='/'>
          <img src={logo} width='50' height='50' alt='ocoord-logo' />
        </a>
        <h2>Not Found</h2>
        <p>
          The page you’re looking for couldn’t be found. You may have typed the
          address incorrectly, or followed an outdated link.
        </p>
        {!hideLink
          &&
          <Link to='/'>Continue</Link>}
      </div>
    </div>
  );
};
