import React, { Component } from 'react';
import { Modal, Row, Spinner, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import styled from 'styled-components';

import { Button, AddEditTask, Input, DateInput } from '../../components';

import addIcon from '../../assets/images/add.svg';
import pencil from '../../assets/images/pencil.svg';
import trash from '../../assets/images/trash.svg';
import sortIcon from '../../assets/images/sort-icon.svg';
import ascendingSortIcon from '../../assets/images/descending-sort-icon.svg';
import descendingSortIcon from '../../assets/images/ascending-sort-icon.svg';
import applyFilterGray from '../../assets/images/filter-apply.svg';
import applyFilterGreen from '../../assets/images/filter-active.svg';

import { AddButton, ButtonContainer, CrosseArrowButton, FilterContainer, OverlayContainer, PaginationSection, TableContainer, TableHead, WhiteButton } from './Style';
import { DropDownStyle } from '../../helpers';

const TaskListContainer = styled.div`
.cursor-not-allowed {
    cursor: not-allowed;
}
`;

class TasksList extends Component {
    state = {
        loading: false,
        tasks: [],
        taskDetails: { name: '' },
        orderBy: 'nameForSort',
        order: 'asc',
        selectedTasks: [],
        pageSize: 10,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        addOrEditTask: false,
        errors: {
            maxTasks: false,
        },
        taskLoading: false,
        saveOrUpdateInProgress: false,
        isDeleteTaskModalOpen: false,
        editTaskModalOpen: false,
        filterDetails: {},
        activeFilterDetails: {},
        filterColumns: {},
        locations: [],
        selectedLocation: this.props.user.location._id
    };

    componentDidMount() {
        this.getMasterData();
        this.getTasks();
    };

    getMasterData = async () => {
        try {
            const { user } = this.props;
            const { data: { payload: locations } } = await axios.get(`/api/v2/master/locations_by_user/${user._id}`);
            this.setState({ locations });
        } catch (error) {
            console.error(error);
        }
    };

    getTasks = async () => {
        try {
            const { user: { location } } = this.props;
            const { pageSize, pageNumber, orderBy, order, activeFilterDetails, selectedLocation } = this.state;
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(`/api/v2/tasks/${selectedLocation ? selectedLocation : location._id}`, { params: { ...activeFilterDetails, pageSize, pageNumber, orderBy, order } });
            const pageCount = Math.ceil(payload.totalCount / pageSize);
            this.setState({ loading: false, tasks: payload.data, totalCount: payload.totalCount, pageCount });
        } catch (error) {
            this.setState({ loading: false });
            console.error(error);
        };
    };

    sort = (orderBy, order) => {
        this.setState({ order, orderBy, pageNumber: 1, pageSize: 10 }, () => {
            this.getTasks();
        });
    };

    handlePageClick = (val) => {
        this.setState({ pageNumber: val.selected + 1 }, () => {
            this.getTasks();
        });
    };

    viewTask = (taskId) => {
        const { history } = this.props;
        history.push('/admin/task-view?taskId=' + taskId);
    };

    addEditTask = (taskDetails) => {
        if (!taskDetails.custom) {
            taskDetails.custom = false;
        };
        this.setState({ editTaskModalOpen: true, taskDetails });
    };

    deleteTaskModalOpen = (taskDetails) => {
        if (!taskDetails.custom) {
            Swal.fire({
                icon: 'info',
                title: 'Can not delete a default task.',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
        } else {
            this.setState({ isDeleteTaskModalOpen: true, taskDetails });
        }
    };

    closePopUpModalDialog = (getTasks) => {
        this.setState({ editTaskModalOpen: false, isDeleteTaskModalOpen: false, taskDetails: {} })
        if (getTasks) {
            this.getTasks();
        };
    };

    onFilterInputChange = (name, value) => {
        const { filterDetails } = this.state;
        filterDetails[name] = value;
        this.setState({ filterDetails });
    };

    applyFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;
        if (colName === 'due_date') {
            activeFilterDetails[colName] = moment(new Date(filterDetails[colName])).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).valueOf();
        } else {
            activeFilterDetails[colName] = filterDetails[colName];
        };
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getTasks();
        });
    };

    clearFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;

        delete filterDetails[colName];
        delete activeFilterDetails[colName];
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getTasks();
        });
    };

    openCloseFilterSection = (colName) => {
        const { filterColumns } = this.state;
        filterColumns[colName] = !filterColumns[colName];
        this.setState({
            filterColumns
        });
    };

    afterTaskUpdateOrAdd = () => {
        this.setState({ pageSize: 10, pageNumber: 1, taskDetails: {}, isDeleteTaskModalOpen: false }, () => {
            this.getTasks();
        });
    };

    deleteTask = async () => {
        try {
            this.setState({ saveOrUpdateInProgress: true });
            const { taskDetails } = this.state;
            console.log(taskDetails)
            await axios.delete('/api/v2/task/' + taskDetails._id);
            this.setState({ saveOrUpdateInProgress: false });
            this.afterTaskUpdateOrAdd();
            Swal.fire({
                icon: 'success',
                title: 'Task has been deleted successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
        } catch (error) {
            this.setState({ saveOrUpdateInProgress: false });
        }
    };

    onLocationChange = async (value) => {
        const selectedLocation = value ? value.value : this.props.user.location._id;
        this.setState({ pageNumber: 1, pageSize: 10, selectedLocation }, () => {
            this.getTasks();
        });
    };


    render() {
        const { tasks, loading, pageNumber, pageCount, orderBy, order, editTaskModalOpen, taskDetails, filterDetails, filterColumns, activeFilterDetails, isDeleteTaskModalOpen, saveOrUpdateInProgress, selectedLocation, locations } = this.state;
        const { user } = this.props;
        const frequencyList = [{ value: '99', label: 'Once' }, { value: '0', label: 'Weekly' }, { value: '1', label: 'Monthly' }, { value: '2', label: 'Quarterly' }, { value: '3', label: 'Yearly' }];
        return (
            <TaskListContainer>
                <Row className="mx-2 mb-3 mt-2">
                    <Col lg='6' md='6' sm='12'></Col>
                    <Col className='d-flex justify-content-end' lg='6' md='6' sm='12'>
                        <Select
                            className='min-w-25'
                            options={locations}
                            value={locations.find(lo => lo.value === selectedLocation)}
                            defaultValue={locations.find(lo => lo.value === selectedLocation)}
                            onChange={this.onLocationChange}
                            placeholder='Locations'
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                            isClearable={selectedLocation === user.location._id ? false : true}
                        />
                        <AddButton onClick={() => this.addEditTask({ custom: true })} className='ml-2 my-0'><img src={addIcon} alt="Add Icon" /> Add Task</AddButton>
                    </Col>
                </Row>
                <TableContainer maxHeight='60vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className='w-15'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.name && !activeFilterDetails.name) && <img onClick={() => this.openCloseFilterSection('name')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.name || activeFilterDetails.name) && <img onClick={() => this.openCloseFilterSection('name')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.name &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.name || ''} onChange={(e) => this.onFilterInputChange('name', e.target.value)} placeholder='Name' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('name')}>Clear</button>
                                                    <button disabled={!filterDetails.name} className='btn btn-success' type='button' onClick={() => this.applyFilter('name')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Task Name
                                    <span>
                                        {(orderBy !== 'nameForSort') && <img onClick={() => this.sort('nameForSort', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'nameForSort' && order === 'asc') && <img onClick={() => this.sort('nameForSort', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'nameForSort' && order === 'desc') && <img onClick={() => this.sort('nameForSort', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-15 pl-4'>
                                    Assigned To
                                </th>
                                <th className='w-15'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.frequency && !activeFilterDetails.frequency) && <img onClick={() => this.openCloseFilterSection('frequency')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.frequency || activeFilterDetails.frequency) && <img onClick={() => this.openCloseFilterSection('frequency')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.frequency &&
                                            <OverlayContainer>
                                                <Select
                                                    name='user'
                                                    placeholder='Frequency'
                                                    options={frequencyList}
                                                    onChange={(e) => this.onFilterInputChange('frequency', e.value)}
                                                    menuPosition="fixed"
                                                    styles={DropDownStyle}
                                                    value={frequencyList.find(fr => fr.value === filterDetails.frequency)}
                                                    required
                                                />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('frequency')}>Clear</button>
                                                    <button disabled={!filterDetails.frequency} className='btn btn-success' type='button' onClick={() => this.applyFilter('frequency')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Frequency
                                    <span>
                                        {(orderBy !== 'frequencyName') && <img onClick={() => this.sort('frequencyName', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'frequencyName' && order === 'asc') && <img onClick={() => this.sort('frequencyName', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'frequencyName' && order === 'desc') && <img onClick={() => this.sort('frequencyName', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-15'>
                                    Created Date
                                    <span>
                                        {(orderBy !== 'createdAt') && <img onClick={() => this.sort('createdAt', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'createdAt' && order === 'asc') && <img onClick={() => this.sort('createdAt', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'createdAt' && order === 'desc') && <img onClick={() => this.sort('createdAt', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-15'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.due_date && !activeFilterDetails.due_date) && <img onClick={() => this.openCloseFilterSection('due_date')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.due_date || activeFilterDetails.due_date) && <img onClick={() => this.openCloseFilterSection('due_date')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.due_date &&
                                            <OverlayContainer>
                                                <DateInput
                                                    width={'100%'}
                                                    placeholderText='mm-dd-yyyy'
                                                    dateFormat='MM-dd-yyyy'
                                                    value={filterDetails.due_date || ''}
                                                    onChange={(date) => this.onFilterInputChange('due_date', date)}
                                                />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('due_date')}>Clear</button>
                                                    <button disabled={!filterDetails.due_date} className='btn btn-success' type='button' onClick={() => this.applyFilter('due_date')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Due Next
                                    <span>
                                        {(orderBy !== 'due_date') && <img onClick={() => this.sort('due_date', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'due_date' && order === 'asc') && <img onClick={() => this.sort('due_date', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'due_date' && order === 'desc') && <img onClick={() => this.sort('due_date', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-15'>
                                    Last Completed
                                    <span>
                                        {(orderBy !== 'lastCompletedAt') && <img onClick={() => this.sort('lastCompletedAt', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'lastCompletedAt' && order === 'asc') && <img onClick={() => this.sort('lastCompletedAt', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'lastCompletedAt' && order === 'desc') && <img onClick={() => this.sort('lastCompletedAt', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-10 text-center'>
                                    Action
                                </th>
                            </tr>
                        </TableHead>
                        {!loading &&
                            <tbody>
                                {(tasks.length > 0) && tasks.map((task) => (
                                    <tr key={task._id}>
                                        <td className='cursor-pointer w-15 pl-4' onClick={() => this.viewTask(task._id)}>
                                            <CrosseArrowButton>{task.name}</CrosseArrowButton>
                                        </td>
                                        <td className='w-15 pl-4'>{(task.users && task.users.length) ? (task.users.length === 1 ? task.users[0].fullName : task.users.length + ' Members') : "No user assigned."}</td>
                                        <td className='w-15'>{task.frequencyName}</td>
                                        <td className='w-15'>{moment(task.createdAt).format('MM / DD / YYYY')}</td>
                                        <td className='w-15'>{moment(task.due_date).format('MM / DD / YYYY')}</td>
                                        <td className='w-15'>{task.lastCompletedAt ? moment(task.lastCompletedAt).format('MM / DD / YYYY') : '--'}</td>
                                        <td className='text-center w-10'>
                                            <img onClick={() => this.addEditTask(task)} src={pencil} className='mr-3 cursor-pointer' alt="Edit" />
                                            <img className='cursor-pointer mr-2' onClick={() => this.deleteTaskModalOpen(task)} src={trash} alt="Delete Arrow" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                    {(tasks.length <= 0 && !loading) &&
                        <div>
                            <h4 className='text-center'> No data found</h4>
                        </div>
                    }
                    {
                        loading &&
                        <div className='w-100 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                    }
                </TableContainer>

                <PaginationSection>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        previousLabel="<"
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                    />
                </PaginationSection>
                {editTaskModalOpen && <AddEditTask task={taskDetails._id} user={user} show={editTaskModalOpen}
                    closePopUpModal={this.closePopUpModalDialog} afterTaskUpdateOrAdd={this.afterTaskUpdateOrAdd} />
                }
                {isDeleteTaskModalOpen &&
                    <Modal
                        centered
                        animation={true}
                        backdrop="static"
                        keyboard={false}
                        show={isDeleteTaskModalOpen}>
                        <Modal.Body>
                            <div className='text-center mt-2'>
                                <h3>Are you sure?</h3>
                                <h5 className='my-3'>Do you want to delete the task? This process cannot be undo.</h5>
                            </div>

                            <Modal.Footer className='p-0'>
                                <div className='d-flex justify-content-end'>
                                    <Button className='my-0 mr-3' type='button' onClick={() => this.closePopUpModalDialog()}>No</Button>
                                    <WhiteButton disabled={saveOrUpdateInProgress} className='my-0' type='button' onClick={() => this.deleteTask()}>{saveOrUpdateInProgress ? 'Deleting...' : 'Yes'}</WhiteButton>
                                </div>
                            </Modal.Footer>
                        </Modal.Body>
                    </Modal>
                }
            </TaskListContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(TasksList);